<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
import draggable from 'vuedraggable';
import BoField from './components/BoField.vue';

export default {
  name: "Product",
  extends: Base,
  components: {
    draggable
  },
  data(){
    return {
      Name:"Product",
      mrProdCat: [],
      media: [],
      input:{},
      category: {},
      statusProduct: {},
      row:{category:'',statusProduct:'',ap_image:[]},
      mpTitleLength:0,
      endDragMedia:[],
      data:{data:[]},
      linkDownload:"",
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
    this.refreshData()
  },
  methods: {
    formatRupiah(value) {
        if (isNaN(value)) return value;
        value = parseFloat(value);
        return value.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0
        });
    },
    endDrag() {
      BOGen.apirest('/' + this.Name, { data: this.data.data, type: 'sort' }, (err, resp) => {
        console.log(resp)
      }, "POST");
    },
    addRow(){
       this.row.ap_variant.push({variant:'',ap_sub_variant:[{}]});
    },
    sort(){
      this.filter.sort = this.filter.sort == 'all' ? '' : 'all'
      if(this.filter.sort == 'all'){
        this.filter.category=''
        this.filter.statusProduct=''
      }
      var query = this.filter
      delete query.page
      this.$router.push({name:this.Name, query: query})
    },
    endDragSlider() {
				BOGen.apirest('/' + this.Name, {
					data: this.data,
					type: 'sortMedia'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
		},
    submitFormSlider(e, callback) {
      if (e && e.btnLoading()) return;
      if(this.input.am_id){
        this.input.type = "updateMedia"
      }
      else {
        this.input.am_product_id = this.row.ap_id
        this.input.type = "addMedia"
      }

      BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
        if (e) e.btnUnloading()
        if (err) {
          if (err.status == 422) {
            Object.keys(err.responseJSON).forEach((k) => {
              $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
            })
          }
          return
        }
        if (resp.error) {
          if (this.errorFormCallback) return this.errorFormCallback(resp);
          if (callback) return callback(resp);
          return Gen.info(resp.message, "danger", 2000, '.image-info')
        }
        if (resp.success) {
          if (this.successFormCallback) return this.successFormCallback(resp);
          if (callback) return callback(resp);
          setTimeout(() => {
            this.refreshData()
          }, 2000)
          return Gen.info(resp.message, "success", 1000, '.image-info').then(() => {
            this.input = {}
            this.$router.push({
              name: this.Name,
              params: {
                id: this.$route.params.id
              }
            })
          })
        }
      }, "POST")
    },
    editMedia(v) {
      this.input = v;
    },
    deleteMedia(id) {
      var v = id
      swal({
        title: "Are you sure to delete " + this.ObjectName + "?",
        text: "",
        icon: "warning",
        buttons: ["No, Cancel!", "Yes, Sure!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest("/" + this.Name, {
            type: "delMedia",
            id: v
          }, () => {
            swal("Deleted!", "Information has been deleted.", "success");
            this.refreshData()
          }, "POST")
        }
      });
    },

    submitFormData(e, callback) {
      if (e && e.btnLoading()) return;
      this.row.type = this.$route.params.id != 'add' ? 'update' : 'add'
      BOGen.apirest("/" + this.Name, this.row, (err, resp) => {
        if (e) e.btnUnloading()
        if (err) {
          if (err.status == 422) {
            Object.keys(err.responseJSON).forEach((k) => {
              $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
            })
          }
          return
        }
        if (resp.error) {
          if (this.errorFormCallback) return this.errorFormCallback(resp);
          if (callback) return callback(resp);
          return Gen.info(resp.message, "danger")
        }
        if (resp.success) {
          // if (this.successFormCallback) return this.successFormCallback(resp);
          // if (callback) return callback(resp);
          // setTimeout(() => {
          // 	this.refreshData()
          // }, 2000)
          return Gen.info(resp.message, "success", 1000).then(() => {
            this.$router.push({
              name: this.Name,
              params: {
                id: resp.id
              }
            })
          })
        }
      }, "POST")
    },
    downloadExcel() {
      BOGen.apirest('/' + this.Name, {
					data: this.filter,
					type: 'export'
				}, (err, resp) => {
					console.log(resp)
				}, "GET");
    }
  },
  watch:{
    '$route.query'(){
      if(this.$route.params.id){
        this.$set(this.row, 'category', this)
        this.$set(this.row, 'statusProduct', this)
        this.$set(this.row, 'search', this)
        this.$set(this.row, 'page', this)
        setTimeout(()=>{
          this.row.category = this.$route.query.category
          this.row.statusProduct = this.$route.query.statusProduct
          this.row.search = this.$route.query.search
          this.row.page = this.$route.query.page
        },500)
      }
      this.refreshData()
    },
    'filter.category'(v){
      if(!v) return
        this.search()
    },
    'filter.statusProduct'(v){
      if(!v) return
        this.search()
    },
    'row.ap_title'(v) {
        if (!v) return
        this.mpTitleLength = v.length
    },
  }  
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <!-- ======================================================================== 
      LIST DATA
    ============================================================================= -->
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-header">
             <VForm @resp="search">
              <div class="row">
                <div class="col-sm-3">
                  <h5 class="card-title">{{ObjectName}} List</h5>
                </div>
                <div class="col-sm-2">
                  <select2 :options="category" :object="['apc_id','apc_name']" v-model="filter.category">
                    <option value="">-- Select Category --</option>
                  </select2>
                </div>
                <div class="col-sm-2">
                  <select2 :options="statusProduct" v-model="filter.statusProduct">
                    <option value="">-- Select Status --</option>
                  </select2>
                </div>
                <div class="col-sm-2">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input type="text" v-model="filter.search" v-on:keyup.enter="search"  class="form-control" placeholder="Search...">
                        <div class="input-group-append">
                          <button class="btn btn-info" type="button" @click="search()">
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <router-link :to="{name:Name}" class="btn btn-warning btn-block">Reset</router-link>
                  </div>
                  <div class="col-sm-2 text-right">
                    <a :href="link_download" target="_blank" class="btn btn-success">Download Excel</a>
                  </div>
                </div>
              </VForm>
          </div>

          <div class="card-body">
            <draggable v-model="data.data" tag="div" class="row" @end="endDrag">
              <div class="col-md-3 mb-3" v-for="(v,k) in data.data" :key="k">
                <div class="item-list collection" >
                  <div class="row text-center">
                    <div class="col-md-12">
                      <div class="product-img recipe-cat wrap_slider_img">
                        <template v-if="v.media">
                          <img :src="uploader(v.media.am_file)" alt="prod">
                        </template>
                        <template v-else >
                          <img src="https://placehold.co/287x363" alt="prod">
                        </template>
                        
                        <div class="pro-img-overlay">
                          <router-link class="bg-info bullet_edit" :to="{name:Name,params:{id:v.id},query:$route.query}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
                          <a href="javascript:;" class="bg-danger bullet_delete" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                        </div>
                        <span class="label label-success" v-if="v.ap_is_active=='Y'">Active</span>
                        <span class="label label-danger" v-else>Inactive</span>
                        <div class="slider_name">
                          <p>{{v.ap_name}}</p>
                          <p>Stock: {{ v.ap_stock }}</p>
                          <p>{{ formatRupiah(v.ap_admin_price) }}</p>
                        </div>
                      </div>        
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
              <div v-if="NotFound" class="text-center col-md-12">
                <h5 class="tc">{{NotFound}}</h5>
              </div>
              <div v-if="data.data===false"  class="text-center col-md-12">
                <LoadingSpinner light></LoadingSpinner>
              </div>
              <div class="row">
                 <div class="col-12 justify-content-end">
                    <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                 </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- ======================================================================== 
      FORM CRUD 
    ============================================================================= -->
    
    <div class="card" v-if="$route.params.id">
      <VForm @resp="submitFormData" method="post">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-8">
              <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
            </div>
          </div>
          <div class="info"></div>
          <div class="row">
            <div class="col-md-6">
              <BoField name="ap_name" v-model="row.ap_name"></BoField>
              <BoField name="ap_product_category_id">
                <select2 name="ap_product_category_id" v-bind="validation('ap_product_category_id')" :options="category" :object="['apc_id','apc_name']" v-model="row.ap_product_category_id">
                  <option value="">-- Select Category --</option>
                </select2>
              </BoField>
              <BoField name="ap_desc" mandatory class="mb-0">
                <CKEditor name="ap_desc" v-model="row.ap_desc" v-bind="validation('ap_desc')"></CKEditor>
              </BoField>
            </div>
            <div class="col-sm-3">
              <BoFieldGroup name="ap_member_price" :attr="validation('ap_member_price')">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">RP</span>
                </div>
                <input type="number" class="form-control" name="ap_member_price" v-model="row.ap_member_price" >
              </BoFieldGroup>
              <BoFieldGroup name="ap_admin_price" :attr="validation('ap_admin_price')">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">RP</span>
                </div>
                <input type="number" class="form-control" name="ap_admin_price" v-model="row.ap_admin_price" >
              </BoFieldGroup>
              <BoFieldGroup name="ap_price_after_discount" :attr="validation('ap_price_after_discount')">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">RP</span>
                </div>
                <input type="number" class="form-control" name="ap_price_after_discount" v-model="row.ap_price_after_discount" >
              </BoFieldGroup>
              <BoFieldGroup name="ap_discount" :attr="validation('ap_discount')">
                <input type="number" class="form-control" name="ap_discount" v-model="row.ap_discount" >
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">%</span>
                </div>
              </BoFieldGroup>
              <BoField name="ap_colors" mandatory>
                <TagsInput name="ap_colors" v-model="row.ap_colors" :attr="validation('ap_colors')" :placeholder="'Add color and enter'"></TagsInput>
              </BoField>
              <BoField name="ap_size" mandatory class="mb-0">
                <TagsInput name="ap_size" v-model="row.ap_size" :attr="validation('ap_size')" :placeholder="'Add Size and enter'"></TagsInput>
              </BoField>
              <BoField name="ap_is_new_arrival">
                  <div class="row">
                    <radio name="ap_is_new_arrival" v-model="row.ap_is_new_arrival" option="Y" :attr="validation('ap_is_new_arrival')">Yes</radio>
                    <radio name="ap_is_new_arrival" v-model="row.ap_is_new_arrival" option="N">No</radio>
                  </div>
              </BoField> 
              <BoField name="ap_is_exclusive">
                  <div class="row">
                    <radio name="ap_is_exclusive" v-model="row.ap_is_exclusive" option="Y" :attr="validation('ap_is_exclusive')">Yes</radio>
                    <radio name="ap_is_exclusive" v-model="row.ap_is_best_sellar" option="N">No</radio>
                  </div>
              </BoField> 
            </div>
            <div class="col-sm-3">
              <BoFieldGroup name="ap_weight" :attr="validation('ap_weight')">
                <input type="number" class="form-control" name="ap_weight" v-model="row.ap_weight" >
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Gram</span>
                </div>
              </BoFieldGroup>  
              <BoFieldGroup name="ap_width" :attr="validation('ap_width')">
                <input type="number" class="form-control" name="ap_width" v-model="row.ap_width" >
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">CM</span>
                </div>
              </BoFieldGroup>
              <BoFieldGroup name="ap_length" :attr="validation('ap_length')">
                <input type="number" class="form-control" name="ap_length" v-model="row.ap_length" >
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">CM</span>
                </div>
              </BoFieldGroup>
              <BoFieldGroup name="ap_height" :attr="validation('ap_height')">
                <input type="number" class="form-control" name="ap_height" v-model="row.ap_height" >
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">CM</span>
                </div>
              </BoFieldGroup>
              <BoField name="ap_stock" v-model="row.ap_stock"></BoField>      
              <BoField name="ap_is_active">
                  <div class="row">
                    <radio name="ap_is_active" v-model="row.ap_is_active" option="Y" :attr="validation('ap_is_active')">Active</radio>
                    <radio name="ap_is_active" v-model="row.ap_is_active" option="P">Need Verified</radio>
                    <radio name="ap_is_active" v-model="row.ap_is_active" option="N">Inactive</radio>
                  </div>
              </BoField> 
              <BoField name="ap_is_popular">
                  <div class="row">
                    <radio name="ap_is_popular" v-model="row.ap_is_popular" option="Y" :attr="validation('ap_is_popular')">Yes</radio>
                    <radio name="ap_is_popular" v-model="row.ap_is_popular" option="N">No</radio>
                  </div>
              </BoField> 
              <BoField name="ap_is_best_sellar">
                  <div class="row">
                    <radio name="ap_is_best_sellar" v-model="row.ap_is_best_sellar" option="Y" :attr="validation('ap_is_best_sellar')">Yes</radio>
                    <radio name="ap_is_best_sellar" v-model="row.ap_is_best_sellar" option="N">No</radio>
                  </div>
              </BoField> 
            </div>
            <div class="col-sm-12">
              <div class="text-right">
                <button v-if="$route.params.id === 'add'" type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit data and add media
                  <i class="icon-arrow-right14 position-right"></i>
                </button>
                <button v-else type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Update data
                  <i class="icon-arrow-right14 position-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </VForm>
    </div>

    <div class="row" v-if="$route.params.id && $route.params.id !== 'add'">
      <div class="col-md-8">
        <div class="card"  >
          <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-12">
                  <h5 class="card-title">Media</h5>
                </div>
                <div class="col-md-12">
                <div class="image-info"></div>
                  <div class="alert alert-info" v-if="media.length === 0">
                    No content media.
                  </div>

                  <draggable v-model="media" class="row" tag="tbody" @end="endDragMedia">
                    <div style="cursor:move;" class="col-md-3" :id="'dat'+v.am_id" v-for="(v,k) in media" :key="k">
                      <div class="wrap_slider_img">
                        <img :src="uploader(v.am_file)" class="img-responsive" />
                        <a data-toggle="collapse" @click="editMedia(v)" href="javascript:;" class="bullet_edit"><i
                            class="fas fa-pencil-alt"></i></a>
                        <a href="javascript:;" @click="deleteMedia(v.am_id,'dat'+v.am_id)" class="bullet_delete"><i
                            class="far fa-trash-alt"></i></a>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card"  >
          <div class="card-body">
            <VForm @resp="submitFormSlider" method="post">
              <input type="hidden" v-model="input.am_id">
              <div class="row mt-4">
                <div class="col-12 info"></div>
                <div class="col-12 mb-3">
                  <label>Upload Media</label>
                  <BoField name="as_img">
                    <Uploader name="as_img" :param="{thumbnail:true}" :attr="{required:1}" v-model="input.am_file" type="product"
                      uploadType="cropping"></Uploader>
                  </BoField>
                  <button type="submit" class="btn btn-rounded btn-info btn-loading btn-block">Save Images</button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
